import { isAuthenticatedSelector, removeUndefinedDeep } from "@keepeek/commons";
import { UiSchema } from "@rjsf/utils";
import { JSONSchema7 } from "json-schema";
import { selectorFamily, waitForAll } from "recoil";

import logger from "../../../lib/logger-utils";
import { frontEditHasBeenClosedXTimesState } from "../atoms/frontEdit";
import { CurrentEditKey } from "../atoms/frontEditCurrentEditKeys";
import { SectionData } from "../hooks/useFrontEditCurrentEdit";
import { axiosFetch } from "../utils";
import { getCurrentContentEditsSelector } from "./currentContentEdits";

export const getJsonSchemaSelector = selectorFamily<JSONSchema7 | undefined, CurrentEditKey>({
  key: "GetJsonSchemaSelector",
  get:
    (key) =>
    async ({ get }) => {
      // Invalidate cache when closed to have up to date data on reopening
      get(frontEditHasBeenClosedXTimesState);

      const isAuthenticated = get(isAuthenticatedSelector);
      if (!isAuthenticated) {
        logger.debug("getJsonSchemaSelector can't fetch without authentication");
        return undefined;
      }
      if (key.configSection) {
        return axiosFetch(`/api/schema/${key.configSection}`);
      }
    },
});

export const getJsonSchemaUiSelector = selectorFamily<UiSchema | undefined, CurrentEditKey>({
  key: "GetJsonSchemaUiSelector",
  get:
    (key) =>
    async ({ get }) => {
      // Invalidate cache when closed to have up to date data on reopening
      get(frontEditHasBeenClosedXTimesState);

      const isAuthenticated = get(isAuthenticatedSelector);
      if (!isAuthenticated) {
        logger.debug("getJsonSchemaUiSelector can't fetch without authentication");
        return undefined;
      }
      if (key.configSection) {
        return axiosFetch(`/api/ui-schema/${key.configSection}`);
      }
    },
});

export const getConfigurationSelector = selectorFamily<Object, CurrentEditKey>({
  key: "GetConfigurationSelector",
  get:
    (key) =>
    async ({ get }) => {
      // Invalidate cache when closed to have up to date data on reopening
      get(frontEditHasBeenClosedXTimesState);

      const isAuthenticated = get(isAuthenticatedSelector);
      if (!isAuthenticated) {
        logger.debug("getWidgetEditoConfiguration can't fetch without authentication");
        return undefined;
      }
      if (key.widgetId && key.configSection) {
        return axiosFetch(
          `/api/edit/widgets/${key.configSection}/${encodeURIComponent(key.widgetId)}`,
        );
      } else if (key.configSection && key.namespaceLabelKey) {
        return axiosFetch(`/api/edit/labels/${key.namespaceLabelKey}`);
      } else {
        return axiosFetch(`/api/edit/${key.configSection}`);
      }
    },
});

const getDataOfSectionSelector = selectorFamily<SectionData | undefined, { key: CurrentEditKey }>({
  key: "getDataOfSectionSelector",
  get:
    ({ key }) =>
    ({ get }) => {
      // Get current edited content to display right data when close editor and re-open it
      let endpoint = "";
      if (key.widgetId) {
        endpoint = `${key.configSection}/${key.widgetId}`;
      } else if (key.namespaceLabelKey) {
        endpoint = `${key.configSection}/${key.namespaceLabelKey}`;
      } else {
        endpoint = key.configSection;
      }
      const currentContentEdits = get(
        getCurrentContentEditsSelector({
          endpoint,
        }),
      );

      const { jsonSchemaValue, jsonUiSchemaValue, databaseConfigurationData } = get(
        waitForAll({
          jsonSchemaValue: getJsonSchemaSelector(key),
          jsonUiSchemaValue: getJsonSchemaUiSelector(key),
          databaseConfigurationData: getConfigurationSelector(key),
        }),
      );
      if (jsonSchemaValue && jsonUiSchemaValue) {
        return {
          key,
          jsonSchema:
            key.widgetId || key.namespaceLabelKey ? jsonSchemaValue.items : jsonSchemaValue,
          jsonUiSchema:
            key.widgetId || key.namespaceLabelKey ? jsonUiSchemaValue.items : jsonUiSchemaValue,
          configurationData: currentContentEdits
            ? currentContentEdits.data
            : databaseConfigurationData,
        };
      }
      return undefined;
    },
});

export const getDataOfMultipleSectionsSelector = selectorFamily<
  SectionData[],
  { keys: CurrentEditKey[] }
>({
  key: "getSchemasOfMultipleSectionsSelector",
  get:
    ({ keys }) =>
    ({ get }) => {
      const data: SectionData[] = removeUndefinedDeep(
        get(waitForAll(keys.map((key) => getDataOfSectionSelector({ key })))),
      );
      return data;
    },
});
